import React from "react"

const Cross = () => {
  return (
    <svg
      fill="none"
      // height="44"
      viewBox="0 0 45 44"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-love-grey hover:fill-black h-[34px] md:h-[44px]"
    >
      <path d="m23.8323 22.181 20.0051-20.00507-1.4142-1.414211-20.0051 20.005081-20.00374-20.003799-1.41421 1.414209 20.00375 20.00379-20.031244 20.0312 1.414214 1.4142 20.03123-20.0312 20.2296 20.2295 1.4142-1.4142z" />
    </svg>
  )
}

export default Cross
