import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import { PortableText } from "@portabletext/react"
import { motion } from "framer-motion"
import Seo from "../components/seo"

//utils
import { variants } from "../utils/framerUtils"

//Portable Text Components
const components = {
  block: {
    subheading: ({ children }) => (
      <h4 className=" text-love-grey mt-lead-1">{children}</h4>
    ),
  },
}

const Contact = ({ data }) => {
  return (
    <Layout footer>
      <Sidebar pageTitle="CONTACT" />
      <motion.div
        variants={variants.fadeUpandInStaggerChildren}
        className="grid grid-cols-4 md:col-start-8 md:col-span-4 gap-lead-1 mt-lead-6 md:mt-0"
      >
        <motion.div variants={variants.fadeUpandIn} className="col-span-2">
          <h3 className="text-love-grey mb-lead-1">ADDRESS</h3>
          <PortableText
            value={data.sanityContactPage._rawAddress}
            components={components}
          />
        </motion.div>
        <motion.div variants={variants.fadeUpandIn} className="col-span-2">
          <h3 className="text-love-grey mb-lead-1">PHONE</h3>
          <PortableText
            value={data.sanityContactPage._rawPhone}
            components={components}
          />
        </motion.div>
        <motion.div variants={variants.fadeUpandIn} className="col-span-2">
          <h3 className="text-love-grey mb-lead-1">SOCIAL</h3>
          <PortableText
            value={data.sanityContactPage._rawSocial}
            components={components}
          />
        </motion.div>
        <motion.div variants={variants.fadeUpandIn} className="col-span-2">
          <h3 className="text-love-grey mb-lead-1">EMAIL</h3>
          <PortableText
            value={data.sanityContactPage._rawEmail}
            components={components}
          />
        </motion.div>
        <motion.div
          variants={variants.fadeUpandIn}
          className="col-span-4 text-love-grey"
        >
          {data.sanityContactPage.additionalText}
        </motion.div>
      </motion.div>
    </Layout>
  )
}

export default Contact
export const Head = () => <Seo title="Contact" />
export const query = graphql`
  query contactQuery {
    sanityContactPage {
      _rawSocial
      _rawPhone
      _rawEmail
      _rawAddress
      additionalText
    }
  }
`
